import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { GlobalStyles, themes } from '../theme'

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SCThemeProvider theme={themes['theme']}>
      <GlobalStyles />
      {children}
    </SCThemeProvider>
  )
}

export default ThemeProvider
